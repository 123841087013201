import home1 from '../../uploads/home/event.jpg'
import homeWebp1 from '../../uploads/home/home1.jpg'
import home2 from '../../uploads/home/home2.jpg'
import homeWebp2 from '../../uploads/home/home2.jpg'
const data =[ 
    {
        key:1,
        heading: "The Royal Retreat",
        paragraphOne: "Introducing The Royal Retreat,  a locally owned and operated property that offers a unique hotel environment with bright, spacious suites designed to make you feel at home.",
        paragraphTwo: "Our location is perfect for visiting family & friends at one of the nearby hospitals, long-term care facilities, or attending one of Rajkot’s many special events. Located at out of downtown Rajkot, The Royal Retreat is ideal for people who want to see their host city convenient for transportation, entertainment, shopping and business centres",
        link:"#",
        imageSrc:home1,
        webSrc:homeWebp1,
        rowReverse:false,
    },
    {
        key:2,
        heading: "Saffron - Multi Cuisine Restaurant",
        paragraphOne: "The Royal Retreat is THE destination for an exotic and intimate wedding. Spread out over 4 acres, use a combination of both indoor and outdoor spaces to host a wedding of up to 3000 pax, each with unique settings, ambience and backdrops. So what are you waiting for?",
        paragraphTwo: "Plan your Happily Ever After in style with a wedding in Rajkot. Pre-wedding cocktail at The Royal Retreat, mehendi at the Banquet while the GREAT ROOM is set for the sangeet or reception, Party Lawns for the main wedding and Welcome/ rehearsal dinner at our Resturant,the kids zone and top it off with an after party  – the options are in plenty with the dynamic spaces.",
        link:"#",
        imageSrc:home2,
        webSrc:homeWebp2,
        rowReverse:true,

    },
]
export default data;