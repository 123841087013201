import React from "react";
import Slider from "../Slider";
import ban2 from "../../uploads/banquet/ban2.jpg";
import ban5 from "../../uploads/banquet/ban5.jpg";
import ban6 from "../../uploads/banquet/ban6.jpg";
import ban7 from "../../uploads/banquet/ban7.jpg";
import ban9 from "../../uploads/banquet/ban9.jpg";
import ban10 from "../../uploads/banquet/ban10.jpg";
import ban11 from "../../uploads/banquet/ban11.jpg";

const BanquetSlider = () => {
  const images = [ban2, ban5, ban6, ban7, ban9, ban10, ban11];

  return <Slider images={images} />;
};

export default BanquetSlider;
