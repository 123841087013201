import React from "react";
import CountUp from "react-countup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import BanquetSlider from "../Component/BanquetSlider";
import p4 from '../uploads/party-lawns/p4.jpg'
import p2 from "../uploads/party-lawns/p2.jpg"
import p3 from "../uploads/party-lawns/p3.jpg"
import p6 from '../uploads/party-lawns/p6.jpg'
const PartyLawns = () => {
  return (
    <>
      <section
        id="home"
        className="fix-background p-top p-bottom bg-party-image res-fix-background"
        style={{ minHeight: "722px" }}
      >
        <div
          className="container full-screen position-relative  res-full-screen party-lawns-scroll"
          style={{ minHeight: "655px !important" }}
        >
        </div>
      </section>
      <section id="Party" className="no-padding margin-top-five">
        <div className="container-fluid margin-top-five">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <p className="title-large  text-uppercase  brown-text display-block  margin-one no-margin-top no-margin-lr">
                For Your All Events Need
              </p>
              <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
            </div>
            <div className="text-content text-left margin-both-5 ">
              <p className="text-medium">
                Guardians of places that have existed long before us, our unique
                vision is inspired by nature’s magnitude, mystery and enchanting
                beauty. Founded on shared passions and masters of innovation, we
                are a thriving community working hand in hand with the
                environment to craft beautiful, beyond bespoke experiences where
                discovery is a way of life. With our expert hosts as your
                personal guide, we invite you to explore and delight in the
                possibility of each moment as time melts away and lose yourself
                in experiences that will stay with you for a lifetime.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row margin-ten no-margin-lr no-margin-bottom">
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-margin-eleven sm-no-margin-lr sm-no-margin-top xs-no-border">
                <CountUp end={3} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        ref={countUpRef}
                        className="timer counter-number brown-text"
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                  Indoor Space
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
                <CountUp end={13} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        ref={countUpRef}
                        className="timer counter-number brown-text"
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                  Events Held
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
                <CountUp end={2000} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        ref={countUpRef}
                        className="timer counter-number brown-text"
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                  People to accommodate
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center">
                <CountUp end={67000} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        ref={countUpRef}
                        className="timer counter-number brown-text"
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                  Sqft. Space
                </span>
              </div>
              {/* end counter item */}
            </div>
          </div>
          {/* Image section */}
          <div className="container">
            <div className="row margin-ten no-margin-lr no-margin-bottom">
              <div className="col-md-3 col-sm-6 counter-style1 text-center no-padding-lr sm-margin-eleven sm-no-margin-lr sm-no-margin-top xs-no-border">
              
                     <LazyLoadImage
                     src={p2}
                  width={800}
                  height={600}
                  alt="curry salad soup"
                  />
              </div>
              <div className="col-md-3 col-sm-6 counter-style1 text-center no-padding-lr sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
              
                     <LazyLoadImage
                    src={p3}
                  width={800}
                  height={600}
                  alt="curry salad soup"
                  />
              </div>
              <div className="col-md-3 col-sm-6 counter-style1 text-center no-padding-lr  xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
                
                     <LazyLoadImage
                     src={p4}
                  width={800}
                  height={600}
                  alt="curry salad soup"
                  />
              </div>
              <div className="col-md-3 col-sm-6 counter-style1 text-center no-padding-lr  xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
               
                <LazyLoadImage
                  src={p6}
                  width={800}
                  height={600}
                  alt="curry salad soup"
                  />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banquet-container  margin-top-five">
        <div className="col-md-12 col-sm-12 text-center">
          <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            DREAM DESTINATION WEDDINGS
          </p>
          <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div>
        </div>
        <p style={{ textAlign: "center" }} className="margin-top">
          {" "}
          An unforgettable way to celebrate the start of newly-married bliss,
          whether an intimate gathering on the sand or a spectacular ceremony in
          front of extended family and friends. All packages are utterly
          bespoke, with exquisite gastronomy and luxurious stays in Royal Suite
          for the happy couple and their guests.
        </p>
      </section>
      <section id="Party" className="no-padding margin-five">
        <div className="container-fluid" style={{ maxHeight: "450px" }}>
          <BanquetSlider />
        </div>
      </section>
      <section className="call-us-now">
        <div className="call-us-container">
          <div>
            <h1 className="title-medium letter-spacing-4 text-uppercase brown-text display-block  no-margin">
              Interested? Call Us Now +91-81408-11118
            </h1>
          </div>
          <div>
            <Link
              to="contact"
              id="restaurant-contact-button"
              type="submit"
              className="highlight-button-dark btn btn-medium text-uppercase"
            >
              {" "}
              Book your stay now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartyLawns;
