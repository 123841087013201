import React from "react";
import ban2 from "../uploads/banquet/ban2.jpg"
import BanquetPageArticles from "../Component/BanquetPageArticles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const banquet = () => {
  return (
    <>
      <section
        id="home"
        className="fix-background p-top p-bottom bg-ban-image res-fix-background"
        style={{minHeight: "722px" }}
      >
        <div
          className="container full-screen position-relative  res-full-screen party-lawns-scroll"
          style={{ minHeight: "570px !important" }}
        >
          <div className="slider-typography text-center">
            <div className="slider-text-middle-main">
              <div className="scrollToText"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex">
        <div className="event-main-div">
               <LazyLoadImage
                    src={ban2}
                    alt="celebration lawn"
                  />

          <div className="event-div">
            <h4>Meetings and Events at The Royal Retreat</h4>
            <p>
              Plan your next gathering in a luxury place. <br /> From corporate
              happenings to team-building retreats, our <br /> Events Team will
              curate a one-of-a-kind stay, perfectly <br /> personalised to you
              and your guests.
            </p>
          </div>
        </div>
      </section>
      
     <BanquetPageArticles />
    </>
  );
};

export default banquet;
