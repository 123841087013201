import React from "react";
import SingleImageSlider from "../SingleImageSlider";
import r1 from "../../uploads/rooms/royalSuite/r1.jpg";
import r2 from "../../uploads/rooms/royalSuite/r2.jpg";
import r3 from "../../uploads/rooms/royalSuite/r3.jpg";
import r4 from "../../uploads/rooms/royalSuite/r4.jpg";
import { Link } from "react-router-dom";


const RoyalSuit = () => {
  const royalSuits = [r1, r2, r3, r4];

  return (
    <section className="margin-top-two res-margin-two">
      <div className="container flex-container">
        <div
          className="row flex-child  no-margin-lr no-margin-bottom"
          style={{ maxWidth: "60%" }}
        >
          <div className="col-md-12 col-sm-12 text-center">
            <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
              ROYAL SUITE
            </p>
            <div className="margin-four no-margin-top">
              <hr className="hr" />
            </div>
          </div>
          <p style={{ marginTop: "30px", fontSize: "15px" }}>
            Uplift your vacation with our ultra-gorgeous Royal Suite. With an
            open restaurant and luxury furniture, our only suite of indulgences
            will treat you to your best vacation ever. From the regal Royal
            decor, and grand private dining area, to classy amenities, every
            inch of the space exudes eminence. Your vacation doesn’t have to
            stop at the doorstep. Come, luxuriate in our Royal Suite like
            royalty themselves.
          </p>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="highlight-button-dark btn btn-medium"
              size="large"
              
            >
              <Link to="contact"> Book Now</Link>
             
            </button>
          </div>
        </div>
        <div className='flex-child  flex-flex-container no-padding'>
        <div
          className="row no-margin party-images"
          style={{ width: "400px" }}
        >
          {/* <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            DELUXE ROOMS
          </p> */}
          {/* <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div> */}
           <div
          className="margin-five flex-child"
          style={{ maxHeight: "450px"}}
        >
          <SingleImageSlider images={royalSuits} />
        </div>
        </div>
      
      </div>
        {/* <div
          className="margin-five flex-child royalSuit_container"
          style={{ maxHeight: "450px"}}
        >
          <SingleImageSlider images={royalSuits} />
        </div> */}
      </div>
    </section>
  );
};

export default RoyalSuit;
