import home1 from '../../uploads/home/home2.jpg'
import homeWebp1 from '../../uploads/home/home2.jpg'
const data =[ 
    {
        key:1,
        heading: "Saffron - Multi Cuisine Restaurant",
        paragraphOne: "The Royal Retreat is THE destination for an exotic and intimate wedding. Spread out over 4 acres, use a combination of both indoor and outdoor spaces to host a wedding of up to 3000 pax, each with unique settings, ambience and backdrops. So what are you waiting for?",
        paragraphTwo: "Plan your Happily Ever After in style with a wedding in Rajkot. Pre-wedding cocktail at The Royal Retreat, mehendi at the Banquet while the GREAT ROOM is set for the sangeet or reception, Party Lawns for the main wedding and Welcome/ rehearsal dinner at our Resturant,the kids zone and top it off with an after party – the options are in plenty with the dynamic spaces.",
        link:"#",
        imageSrc:home1,
        webSrc:homeWebp1,
        rowReverse:false,
    },
]
export default data;