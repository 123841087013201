import React from "react";
import { Link } from "react-router-dom";
import logo from '../uploads/common/the-royal-retreat-logo.png'
import footer1 from '../uploads/banquet/ban2.jpg'
import footer2 from '../uploads/foodGallery/f2.jpg'
import footer3 from '../uploads/party-lawns/p4.jpg'
import footer4 from '../uploads/rooms/suite/r11.jpg'
import footer5 from '../uploads/rooms/royalSuite/r2.jpg'
import footer6 from '../uploads/rooms/kings/r7.jpg'
function Footer() {
  return (
    <>    
    <div className="footer margint40">
			<div className="main-footer">
				<div className="container" >
					<div className="row">
						<div className="col-lg-3 col-sm-3 footer-logo">
							<img    src={logo}
                  width={450}
                  height={250}
                  alt="footer logo"
                  layout='responsive' />
                                <div
                className="text-center footer-social margin-top-eight"
             
              >
                <Link
                  className="btn social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                  to="https://www.facebook.com/"
                >
                  <i className="fa fa-facebook"></i>
               </Link>
                <Link
                  className="btn social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                  to="https://twitter.com/"
                >
                  <i className="fa fa-twitter"></i>
               </Link>
                <Link
                  className="btn social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                  to="https://plus.google.com/"
                >
                  <i className="fa fa-google-plus"></i>
               </Link>
                <Link
                  className="btn social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                  to="https://www.youtube.com/"
                >
                  <i className="fa fa-youtube"></i>
               </Link>
                <Link
                  className="btn social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                  to="https://instagram.com/theroyalretreat_rajkot?utm_medium=copy_link"
                >
                  <i className="fa fa-instagram"></i>
               </Link> 
              </div>
						</div>
						<div className="col-lg-9 col-sm-9">						
							<div className="col-lg-4 col-sm-4 margin-top-32-responsive">
              <div className="margin-bottom-8 res-center" >
                <span
                  className="white-text  font-size-18 "
                >
                  Contact us
                </span>
              </div>
              <ul className="footer-list">
                <li>
                  <div className="social-icon" style={{marginRight:"14px", display:"inline-flex"}}>
                  
                    <i className="fa fa-map-marker" style={{fontSize:"29px"}}></i>
                  
                  </div>
                <div className="white-text">
                  <p className="text-medium no-margin">
                    2nd 150 Feet Ring Road, Opp. Atal Sarovar, Nr. New Race Course, Rajkot-360006
                  </p>

                </div>
                </li>
                <li>
                  <div className="social-icon" style={{marginRight:"14px", display:"inline-flex"}}>
               
                  <i className="fa fa-envelope"  style={{fontSize:"23px"}}></i>

                  </div>
                  <div className="white-text">

                  <p className="text-medium no-margin">
                    info@theroyalretreatrajkot.com
                  </p>
                  </div>
                </li>
                <li>
                  <div className="social-icon" style={{marginRight:"14px", display:"inline-flex"}}>
              
                  <i className="fa fa-phone"  style={{fontSize:"29px"}}></i>

                    
                  </div>
                  <div className="white-text">

                  <p className="text-medium no-margin">
                    +91 81408 11118
                    <br />
                    +91 81408 11119
                  </p>
                  </div>
                </li>
              </ul>
							</div>
							<div className="col-lg-4 col-sm-4 margin-top-20-responsive">
              <div className="margin-bottom-8 res-center" >
                <span
                  className="white-text  margin-left-res font-size-18"
                 
                >
                  Quick Links
                </span>
              </div>
              <ul className="footer-quick-links margin-left-res res-center">
                <li>
                  <Link
                    className="social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/"
                  >
                    Home
                 </Link>
                </li>
                <li>
                  <Link
                    className="social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/restaurant"
                  >
                    Restaurant
                 </Link>
                </li>
                {/* <li>
                  <Link
                    className="social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/foodCourt"
                  >
                    Food Court
                 </Link>
                </li> */}
                <li>
                  <Link
                    className="social-icon button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/banquet"
                  >
                    Banquet
                 </Link>
                </li>
                <li>
                  <Link
                    className=" button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/partyLawns"
                  >
                    Party Lawns
                 </Link>
                </li>
                <li>
                  <Link
                    className=" button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/rooms"
                  >
                    Room
                 </Link>
                </li>
                <li>
                  <Link
                    className=" button no-letter-spacing no-margin padding-one-half no-padding-tb no-padding-left"
                    to="/contact"
                  >
                    Contact Us
                 </Link>
                </li>
              </ul>
							</div>
							<div className="col-lg-4 col-sm-4 margin-top-20-responsive hide-responsive">
              <div className="margin-bottom-8  res-center" >
                <span
                  className="white-text  font-size-18"
                  
                >
                  Collection
                </span>
              </div>
              <div className="footer-collection">
              <Link to="/partyLawns">

                <img
                  src={footer1}
                  width={75}
                  height={75}
                  alt="img 1"
                />
              </Link>
              <Link to="/restaurant">
                
                <img
                  src={footer2}
                  width={75}
                  height={75}
                  alt="img 1"
                />
              </Link>
              <Link to="/restaurant">

                <img
                  src={footer3}
                  width={75}
                  height={75}
                  alt="img 1"
                />
                  </Link>
              <Link to="/rooms">
                <img
                  src={footer4}
                  width={75}
                  height={75}
                  alt="img 1"
                />
                  </Link>
              <Link to="/rooms">

                <img
                  src={footer5}
                  width={75}
                  height={75}
                  alt="img 1"
                />
                  </Link>
              <Link to="/rooms">
                <img
                  src={footer6}
                  width={75}
                  height={75}
                  alt="img 1"
                />
                </Link>
              </div>
							</div>
						</div>
					</div>
				</div>
			</div>
      <div className="pre-footer">
				<div className="container">
					<div className="row">
						<div className="text-center"><p>© The Royal Retreat Rajkot</p></div>
					
					</div>
				</div>
			</div>
		</div>
    </>
  );
}

export default Footer;
