import home1 from '../../uploads/banquet/ban7.jpg'
import homeWebp1 from '../../uploads/home/home1.jpg'
import home2 from '../../uploads/banquet/ban3.jpg'
import homeWebp2 from '../../uploads/home/home2.jpg'
const data =[ 
    {
        key:1,
        heading: "Banquet Hall",
        paragraphOne: "Banquet halls by Royal Retreat in Rajkot offer the best and well-equipped venues for conferences, events, marriages, with good space and exquisite banquet services. Royal Retreat is one of the best hotel in Rajkot, offering exquisite and impeccable services to all",
        paragraphTwo: "We have exclusive choices of banquet venues in our hotels, catering to various occasions and situations. All the events at our hotels are carried out in opulent and state-of-the-art venues. Our hotels carry the essence and grandeur of contemporary as well as traditional ambience and setup.",
        link:"#",
        imageSrc:home1,
        webSrc:homeWebp1,
        rowReverse:false,
    },
    {
        key:2,
        heading: "Conference Hall",
        paragraphOne: "The Royal Retreat have fully air conditioned halls have flexible seating arrangements are valued for their elegant business look, and is suitable for all sorts of the Seminars and conferences",
        paragraphTwo: "Keeping in mind your business requirements, The Royal Retreat offers a well furnish business center. Also there is a Conference hall to host functions and events of all natures ranging from conferences, social gatherings, incentive meets, and seminars, all these facilities are backed with state-of-the-art meeting and conferencing equipments and expert services",
        link:"#",
        imageSrc:home2,
        webSrc:homeWebp2,
        rowReverse:true,

    },
]
export default data;