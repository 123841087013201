import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarSide,
  faTShirt,
  faWheelchair,
  faWifi,
  faMedkit,
  faUtensils,
  faPlaneArrival,
  faTemperature0,
} from "@fortawesome/free-solid-svg-icons";
import RoyalSuit from "../Component/RoyalSuit";
import KingRooms from "../Component/KingRooms";

const rooms = () => {
 
  return (
    <>
      <section
        id="home"
        className="fix-background p-top p-bottom bg-room-image res-fix-background"
        style={{ minHeight: "722px" }}
      >
        <div
          className="container full-screen position-relative  res-full-screen party-lawns-scroll"
          style={{ minHeight: "655px !important" }}
        >
          <div className="slider-typography text-center">
            <div className="slider-text-middle-main">

            </div>
          </div>
        </div>
      </section>
      <section className="margin-top-two">
        <div className="row res-row">
          <div className="col-md-12 col-sm-12 text-center">
            <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
              DASH OF LUXURY
            </p>
            <div className="margin-four no-margin-top">
              <hr className="hr" />
            </div>
          </div>
          <div className="text-content text-left margin-both-5 ">
            <p className="text-medium">
              The Royal Retreat, Rajkot brings an experience of luxury stay in
              rooms and suites. Our rooms are crafted with a luxury touch spirit
              in their style and decor. Plush interiors and royal comfort are
              what our rooms offer bundled with extravagant amenities. Our
              professional and experienced staff is at your service 24 hours a
              day to create a magnificent luxury living experience. Enjoy our
              stay with our incredible facilities at The Royal Retreat, Rajkot.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row margin-ten no-margin-lr no-margin-bottom">
            {/* counter item */}
            <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-margin-eleven sm-no-margin-lr sm-no-margin-top xs-no-border">
              <CountUp start={0} end={3} delay={0} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      className="timer counter-number   brown-text"
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                Categories
              </span>
            </div>
            {/* end counter item */}
            {/* counter item */}
            <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
              <CountUp start={0} end={4} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      className="timer counter-number   brown-text"
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                Suites
              </span>
            </div>
            {/* end counter item */}
            {/* counter item */}
            <div className="col-md-3 col-sm-6 counter-style1 text-center border-right xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
              <CountUp start={0} end={32} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      className="timer counter-number   brown-text"
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                Rooms
              </span>
            </div>
            {/* end counter item */}
            {/* counter item */}
            <div className="col-md-3 col-sm-6 counter-style1 text-center">
              <CountUp start={0} end={67000} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      className="timer counter-number   brown-text"
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
              Sqft. Space
              </span>
            </div>
            {/* end counter item */}
          </div>
        </div>
      </section>

      <RoyalSuit />
      <KingRooms />

      <section className="margin-top-six res-row margin-bottom-3">
        <div className="col-md-12 col-sm-12 text-center">
          <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            STANDARD HOTEL FACILITIES
          </p>
          <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div>
        </div>
        <p className="text-container">
          The Royal Retreat, Rajkotbrings an experience of royal stay in rooms
          and suites. Our rooms are crafted with a luxury touch in their style
          and decor. Plush interiors and royal comfort are what our rooms offer
          bundled with extravagant amenities. Our professional and experienced
          staff is at your service 24 hours a day to create a magnificent royal
          living experience. Enjoy our stay with our incredible facilities at
          The Royal Retreat, Rajkot.{" "}
        </p>
        <div className="service-icon" style={{ textAlign: "center" }}>
          <div className="service-div">
            <div
              style={{ display: "block", flexGrow: "1", marginLeft: "30px" }}
            >
              <div>
                <FontAwesomeIcon icon={faCarSide} size="4x" color="#f2d6a0" />
              </div>
              <div>CITY TOUR</div>
            </div>
            <div style={{ display: "block", flexGrow: "1" }}>
              <div>
                {" "}
                <FontAwesomeIcon icon={faTShirt} size="4x" color="#f2d6a0" />
              </div>
              <div> LAUNDRY</div>
            </div>
            <div style={{ display: "block", flexGrow: "1" }}>
              <div>
                {" "}
                <FontAwesomeIcon
                  icon={faWheelchair}
                  size="4x"
                  color="#f2d6a0"
                />
              </div>
              <div> HANDICAP SERVICES</div>
            </div>
            <div style={{ display: "block", flexGrow: "1" }}>
              <div>
                {" "}
                <FontAwesomeIcon icon={faWifi} size="4x" color="#f2d6a0" />
              </div>
              <div>HIGH SPEED WIFI</div>
            </div>
          </div>
          <div className="service-div">
            <div style={{ display: "block" }} className="m-right">
              <div>
                <FontAwesomeIcon icon={faMedkit} size="4x" color="#f2d6a0" />
              </div>
              <div>MEDICAL SERVICE</div>
            </div>
            <div style={{ display: "block" }} className="m-left">
              <div>
                <FontAwesomeIcon icon={faUtensils} size="4x" color="#f2d6a0" />
              </div>
              <div>
                CUSTOMISED SERVICES:
                <br /> BUTLER SERVICE
              </div>
            </div>
            <div style={{ display: "block" }}>
              <div>
                <FontAwesomeIcon
                  icon={faPlaneArrival}
                  size="4x"
                  color="#f2d6a0"
                />
              </div>
              <div>
                AIRPORT PICK UP
                <br /> AND DROPS
              </div>
            </div>
            <div style={{ display: "block" }}>
              <div>
                {" "}
                <FontAwesomeIcon
                  icon={faTemperature0}
                  size="4x"
                  color="#f2d6a0"
                />
              </div>
              <div>COVID TESTS</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default rooms;
