import React from "react";
import { Link } from "react-router-dom";
import Slider from "../Slider";
import p1 from "../../uploads/party-lawns/p1.jpg";
import p2 from "../../uploads/party-lawns/p2.jpg";
import p3 from "../../uploads/party-lawns/p3.jpg";
import p4 from "../../uploads/party-lawns/p4.jpg";
import p5 from "../../uploads/banquet/ban2.jpg";
import p6 from "../../uploads/party-lawns/p6.jpg";
import p7 from "../../uploads/banquet/ban10.jpg"



const PartyLawnSlider = () => {
  const images = [p1, p2, p3,p4,p5,p6,p7];

  return <>
       <section id="Party" className="no-padding margin-top-five ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <Link to="/partyLawns">
                <h2 className="title-large res-margin-top  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
                  Party Lawns
                </h2>
              </Link>
              <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
            </div>
          </div>
          <Slider images={images} />

        </div>
      </section>
  </>;
};

export default PartyLawnSlider;
