import { BrowserRouter, Switch, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "react-multi-carousel/lib/styles.css";
import "./App.css";
import "./styles/animate.css";
import "./styles/bootstrap.min.css";
// import "./styles/et-line-icons.css";
// import "./styles/font-awesome.min.css";
// import "./styles/owl.transitions.css";
// import "./styles/owl.carousel.css";
import "./styles/magnific-popup.css";
import "./styles/style.css";
import "./styles/responsive.css";
import Layout from "./layout";
import Home from "./pages";
import Contact from "./pages/Contact";
import partyLawns from "./pages/partyLawns";
import Restaurant from "./pages/restaurant";
import Banquet from "./pages/banquet";
import Rooms from "./pages/rooms";
import ScrollToTop from "./Component/ScrollToTop.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/contact" component={Contact} />
              <Route path="/partyLawns" component={partyLawns} />
              <Route path="/banquet" component={Banquet} />
              <Route path="/restaurant" component={Restaurant} />
              <Route path="/rooms" component={Rooms} />
            </Switch>
          </Layout>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
