import React from "react";
import { Link } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";

import food1 from "../../uploads/foodGallery/f1.JPG";
import food2 from "../../uploads/foodGallery/f2.jpg";
import food3 from "../../uploads/foodGallery/f3.jpg";
import food4 from "../../uploads/foodGallery/f4.jpg";
import food5 from "../../uploads/foodGallery/f5.webp";
import food6 from "../../uploads/foodGallery/f6.jpg";
import food7 from "../../uploads/foodGallery/f7.jpg";
import food8 from "../../uploads/foodGallery/f8.jpg";

const FoodGallery = () => {
  return (
    <section
      id="gallery"
      className="no-padding-bottom work-4col margin-top-five"
    >
      <div className="container">
        {/* section title */}
        <div className="row margin-five no-margin-top no-margin-lr">
          <div className="col-md-12 col-sm-12 text-center">
            <h2 className="title-large res-margin-top alt-font text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top ">
              Food Gallery
            </h2>
            <div className="margin-four no-margin-top">
              <hr className="hr" />
            </div>
          </div>
        </div>
        {/* end section title */}
      </div>
      <div className="container-fluid">
     
        <div className="row">
          <div className="grid-gallery grid-style2 overflow-hidden">
            <div className="tab-content">
              <ul className="masonry-items grid lightbox-gallery">
                {/* gallery item */}
                <li className="drinks lunch dinner">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food1}>
                        <img
                          src={food1}
                          width={800}
                          height={600}
                          alt="burger"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Mocktails
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="drinks breakfast dinner">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food2} >
                        <img
                          src={food2}
                          width={800}
                          height={600}
                          alt="beans"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Bombay vadapau
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="lunch breakfast dinner">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food3}>
                        <img
                          src={food3}
                          width={800}
                          height={600}
                          alt="curry salad soup"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Pizzas
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="dinner breakfast lunch">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food4}>
                        <img
                          src={food4}
                          width={800}
                          height={600}
                          alt="crepes of wrath"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Tandoori kabab plater
                      </h3>
                     
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="drinks lunch">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food5}>
                        <img
                          src={food5}
                          width={800}
                          height={600}
                          alt="tang creamsicle"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                       Bombay Style Papadi chat
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="drinks breakfast">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food6}>
                        <img
                          src={food6}
                          width={800}
                          height={600}
                          alt="crepes"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Noodles
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="drinks breakfast">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food7}>
                        <img
                          src={food7}
                          width={800}
                          height={600}
                          alt="arugula flatbread"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Lasagne
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
                {/* gallery item */}
                <li className="drinks breakfast">
                  <figure>
                    <div className="gallery-img">
                      <Link to={food8}>
                        <img
                          src={food8}
                          width={800}
                          height={600}
                          alt="greek yogurt"
                        />
                      </Link>
                    </div>
                    <figcaption>
                      <h3 className="text-large alt-font text-uppercase letter-spacing-2 ">
                        Paubhaji
                      </h3>
                    </figcaption>
                  </figure>
                </li>
                {/* end gallery item */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoodGallery;
