import React from 'react'
import SingleImageSlider from "../SingleImageSlider";
import s1 from "../../uploads/rooms/super/s1.jpg";
import s2 from '../../uploads/rooms/super/s2.jpg';
import s3 from "../../uploads/rooms/super/s3.jpg";
import s4 from "../../uploads/rooms/super/s4.jpg";
import s5 from '../../uploads/rooms/super/s5.jpg';
import s6 from '../../uploads/rooms/super/s6.jpg';
import su1 from '../../uploads/rooms/suite/su1.webp';
import su2 from '../../uploads/rooms/suite/su2.jpg';
import su3 from '../../uploads/rooms/suite/su3.jpg';
import su4 from '../../uploads/rooms/suite/su4.JPG';
import su5 from '../../uploads/rooms/suite/su5.jpg';
import su6 from '../../uploads/rooms/suite/su6.JPG';
import su8 from '../../uploads/rooms/suite/su8.JPG';
import d1 from '../../uploads/rooms/delux/d11.jpg';
import d2 from '../../uploads/rooms/delux/d2.jpg';
import d3 from '../../uploads/rooms/delux/d3.jpg';
import d4 from '../../uploads/rooms/delux/d4.JPG';
import d5 from '../../uploads/rooms/delux/d5.webp';
import d6 from '../../uploads/rooms/delux/d6.webp';



import { Link } from 'react-router-dom';

const KingRooms = () => {
  const superDelux = [s1, s2, s3, s4, s5, s6];
  const suitRoom = [su1, su2, su3, su4, su5, su6, su8];
  const deluxRoom = [d1, d2, d3, d4, d5,d6];
  return (
    <section className='res-row'
    style={{ textAlign: "center", margin: "0 30px", marginTop: "8% " }}
  >
    <div className="room-slider container flex-container">
      <div className='flex-child  flex-flex-container'>
        <div
          className="row no-margin party-images"
          style={{ width: "400px" }}
        >
          <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            DELUXE ROOMS
          </p>
          <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div>
           <div
          className="margin-five flex-child"
          style={{ maxHeight: "450px"}}
        >
          <SingleImageSlider images={deluxRoom} />
        </div>
        </div>
        <button
          style={{ marginTop: "30px" }}
          className="highlight-button-dark btn btn-medium"
          size="large"
        >
          <Link to="contact">Book Now</Link>
        </button>
      </div>
      <div className='flex-child  flex-flex-container'>
        <div
          className="row no-margin party-images"
          style={{ width: "400px" }}
        >
          <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            SUITES ROOMS
          </p>
          <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div>
           <div
          className="margin-five flex-child"
          style={{ maxHeight: "450px"}}
        >
          <SingleImageSlider images={suitRoom} />
        </div>
        </div>

        <button
          className="highlight-button-dark btn btn-medium"
          style={{ marginTop: "30px" }}
        >
           <Link to="contact">Book Now</Link>
        </button>
      </div>
      <div className='flex-child  flex-flex-container' >
        <div
          className="row no-margin party-images"
          style={{ width: "400px" }}
        >
          <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
            SUPER DELUXE
          </p>
          <div className="margin-four no-margin-top">
            <hr className="hr" />
          </div>
           <div
          className="margin-five flex-child"
          style={{ maxHeight: "450px" }}
        >
          <SingleImageSlider images={superDelux} />
        </div>
        </div>
        <button
          style={{ marginTop: "30px" }}
          size="large"
          className="highlight-button-dark btn btn-medium"
        >
          <Link to="contact">Book Now</Link>
        </button>
      </div>
    </div>
  </section>

  )
}

export default KingRooms