import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import chef from "../../uploads/restaurant/chefs.webp";
const Restaurant = ({ id }) => {
  return (
    <section id="restaurant">
      <div className="container sm-text-center">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            <Link to="/restaurant">
              <h2 className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one res-margin-top no-margin-top no-margin-lr">
                Restaurant
              </h2>
            </Link>
            <div className="margin-four no-margin-top">
              <hr className="hr" />
            </div>
          </div>
        </div>
        <div className="row no-margin-lr" style={{ padding: "0 7%" }}>
          <div className="col-md-8 col-sm-12">
           
            <LazyLoadImage
                    alt={"alt"}
                    effect="blur"
                    src={chef}
                    className="home-img res-home-img"
                   
                  />
          </div>
          <div className="col-md-4 col-sm-12 margin-nineteen-top no-margin-lr sm-no-margin-bottom">
            <span className="text-large text-uppercase display-block letter-spacing-1 margin-four no-margin-lr  ">
              Speciality of the House
            </span>
            <span className="title-extra-large text-uppercase   light-gray-text display-block xs-title-medium">
              Discover our tasty meals
            </span>
            <div className="separator-line-thick bg-deep-yellow no-margin-lr sm-margin-lr-auto sm-margin-seven"></div>
            <p className="text-medium margin-fifteen no-margin-lr sm-width-100 sm-margin-seven xs-margin-seven xs-no-margin-lr">
              The Royal Retreat offers enticing dining and entertainment
              concepts for guests and locals alike to enjoy. Our unique options
              such as grab-and-go breakfast fare and sweets, thoughtful
              cocktails along with the exclusive lounge…
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Restaurant;
