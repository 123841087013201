import React from "react";
import ContactComp from "../Component/ContactComp";

const Contact = () => {
  return (
    <>
        <ContactComp />
    </>
  );
};

export default Contact;
