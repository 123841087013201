import React from 'react'
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
const HomeCounter = () => {
  return (
    <>   <section className="no-padding-top">
    <div className="container">
      <div className="row margin-ten no-margin-lr no-margin-bottom">
        {/* counter item */}
        <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-margin-eleven sm-no-margin-lr sm-no-margin-top xs-no-border">
          <CountUp end={780} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span
                  ref={countUpRef}
                  className="timer counter-number brown-text"
                />
              </VisibilitySensor>
            )}
          </CountUp>
          {/* <span className="timer counter-number   brown-text" data-to="780" data-speed="7000"></span> */}
          <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
            Clients Served
          </span>
          <i className="icon-heart icon-small deep-yellow-text"></i>
        </div>
        {/* end counter item */}
        {/* counter item */}
        <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
          <CountUp end={800} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span
                  ref={countUpRef}
                  className="timer counter-number brown-text"
                />
              </VisibilitySensor>
            )}
          </CountUp>
          {/* <span className="timer counter-number   brown-text" data-to="987" data-speed="7000"></span> */}
          <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
            Hours Cooking
          </span>
          <i className="icon-clock icon-small deep-yellow-text"></i>
        </div>
        {/* end counter item */}
        {/* counter item */}
        <div className="col-md-3 col-sm-6 counter-style1 text-center border-right xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
          <CountUp end={350} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span
                  ref={countUpRef}
                  className="timer counter-number brown-text"
                />
              </VisibilitySensor>
            )}
          </CountUp>
          {/* <span className="timer counter-number   brown-text" data-to="350" data-speed="7000"></span> */}
          <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
            Table Booking
          </span>
          <i className="icon-wine icon-small deep-yellow-text"></i>
        </div>
        {/* end counter item */}
        {/* counter item */}
        <div className="col-md-3 col-sm-6 counter-style1 text-center">
          <CountUp end={166} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span
                  ref={countUpRef}
                  className="timer counter-number brown-text"
                />
              </VisibilitySensor>
            )}
          </CountUp>
          {/* <span className="timer counter-number   brown-text" data-to="166" data-speed="7000"></span> */}
          <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
            Food Recipes
          </span>
          <i className="icon-wallet icon-small deep-yellow-text"></i>
        </div>
        {/* end counter item */}
      </div>
    </div>
  </section>
  <section className="call-us-now">
    <div className="call-us-container">
      <div>
        <h1 className="title-medium letter-spacing-4 text-uppercase brown-text display-block  no-margin">
          Interested? Call Us Now +91-81408-11118
        </h1>
      </div>
      <div>
        <Link
          to="contact"
          id="restaurant-contact-button"
          type="submit"
          className="highlight-button-dark btn btn-medium text-uppercase"
        >
          {" "}
          Book your stay now
        </Link>
      </div>
    </div>
  </section></>
  )
}

export default HomeCounter