import React from "react";
import { useState } from "react";

const ContactComp = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });
  // eslint-disable-next-line  no-unused-vars
  const [submitted, setSubmitted] = useState(false);
  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = userData;
    fetch("/api/contact", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        setSubmitted(true);
        setUserData("");
      }
    });
  };
  return (
    <>
      <section
        id="home"
        className="fix-background p-top p-bottom bg-party-lawns-image res-fix-background"
        style={{ minHeight: "722px" }}
      >
        <div
          className="container full-screen position-relative  res-full-screen party-lawns-scroll"
          style={{ minHeight: "655px !important" }}
        ></div>
      </section>
      <section className="contact-margin">
        <div className="container">
          {/* section title */}
          <div className="row res-margin-six margin-top-four no-margin-lr">
            <div className="col-md-12 col-sm-12 text-center">
              <p className="title-large  text-uppercase  brown-text display-block  margin-one no-margin-top no-margin-lr">
                Contact Us
              </p>
              <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
            </div>
          </div>
          {/* end section title */}
          <div className="row">
            {/* text */}
            <div className="col-md-5 col-sm-12 margin-nine no-margin-bottom no-margin-lr xs-text-center xs-no-margin">
              <div className="col-md-1 col-sm-1">
                <i className="icon-map-pin icon-small deep-yellow-text margin-one-half no-margin-lr"></i>
              </div>
              <div className="col-md-11 col-sm-3 xs-margin-nine xs-no-margin-lr xs-no-margin-top">
                <span className="text-uppercase  black-text font-weight-700">
                  Our Restaurant
                </span>
                <p className="text-medium">
                  2nd, 150 Feet Ring Road, Nr. New Race Course, Ghanteshwar.
                </p>
              </div>
              <div className="col-md-1 col-sm-1 margin-twelve no-margin-lr sm-no-margin">
                <i className="icon-clipboard icon-small deep-yellow-text margin-one-half no-margin-lr"></i>
              </div>
              <div className="col-md-11 col-sm-3 margin-twelve no-margin-lr sm-no-margin xs-margin-nine xs-no-margin-lr xs-no-margin-top">
                <span className="text-uppercase  black-text font-weight-700">
                  General Enquiries
                </span>
                <p className="text-medium">info@theroyalretreatrajkot.com</p>
              </div>
              <div className="col-md-1 col-sm-1">
                <i className="icon-phone  icon-small deep-yellow-text margin-one-half no-margin-lr"></i>
              </div>
              <div className="col-md-11 col-sm-3">
                <span className="text-uppercase  black-text font-weight-700">
                  Call us
                </span>
                <p className="text-medium">+91 81408 11118</p>
              </div>
            </div>
            {/* end text */}
            <div className="col-md-7 col-sm-12 margin-nine no-margin-bottom no-margin-lr position-relative">
              <form id="restaurantcontactform" method="post">
                <div id="success" className="no-margin-lr"></div>
                {/* input */}
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="* YOUR NAME"
                  className="big-input "
                  onChange={(e) => handleInput(e)}
                />
                {/* end input */}
                {/* input  */}
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="* YOUR EMAIL"
                  className="big-input "
                  onChange={(e) => handleInput(e)}
                />
                <input
                  type="text"
                  name="phone"
                  id="email"
                  placeholder="* YOUR Phone Number"
                  className="big-input "
                  onChange={(e) => handleInput(e)}
                />

                {/* end input */}
                {/* dropdown  */}
                {/* <div className="select-style big-select ">
                                 <select id="interestedin" name="interestedin" defaultValue={"default"}>
                                    <option value={"default"}>WHAT ARE YOU INTERESTED IN?</option>
                                    <option value="Banquets & Catering">Banquets & Catering</option>
                                    <option value="Private Parties">Private Parties</option>
                                    <option value="Breakfast">Breakfast</option>
                                    <option value="Lunch">Lunch</option>
                                    <option value="Dinner">Dinner</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div> */}
                {/* end dropdown  */}
                {/* textarea  */}
                <textarea
                  name="comment"
                  id="comment"
                  placeholder="YOUR MESSAGE"
                  className="big-input "
                  onChange={(e) => handleInput(e)}
                ></textarea>
                {/* end textarea  */}
                {/* button  */}
                <button
                  id="restaurant-contact-button"
                  type="submit"
                  className="highlight-button-dark btn btn-medium"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {" "}
                  Submit
                </button>
                {/* end button  */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComp;
