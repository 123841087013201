import { Link } from "react-router-dom";
import Restaurant from "../Component/Restaurant";
import MainPageArticles from "../Component/MainPageArticles";
import PartyLawnSlider from "../Component/PartyLawnSlider";
import FoodGallery from "../Component/FoodGallery";
import HomeCounter from "../Component/HomeCounter.js";
import HomeBookRoom from "../Component/HomeBookRoom";

export default function Home() {
  return (
    <>
      <title>The Royal Retreat</title>
      <section
        id="home"
        className="fix-background p-top p-bottom bg-home-image res-fix-background"
        style={{ minHeight: "722px" }}
      >
        <div className="container full-screen position-relative  res-full-screen">
          <div className="slider-typography text-center">
            <div className="slider-text-middle-main"></div>
          </div>
        </div>
      </section>
      <MainPageArticles />
      <Restaurant />
      <PartyLawnSlider />
      <FoodGallery />
      <HomeBookRoom />
      <HomeCounter />
      {/* scroll to top */}
      <Link to="" className="scrollToTop">
        <i className="fa fa-angle-up"></i>
      </Link>
      {/* end scroll to top */}
    </>
  );
}
