/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {  Link } from "react-router-dom";
import logo from '../uploads/common/the-royal-retreat-logo.png'
function Header() {
  return (
    <>
      <nav className="navbar no-margin-bottom">
        <div className="container navigation-menu">
          <div className="row m-top res-nav">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="col-lg-2 col-md-3 ">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link to="/" className="inner-link">
                  <img
                    src={logo}
                    width={156}
                    height={70}
                    alt="logo"
                  />
                </Link>
              
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-9 collapse navbar-collapse m-top p-left"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav">
                <li>
                  <Link to="/" className="inner-link">
                   Home
                  </Link>
                </li>
                <li>
                  <Link to="restaurant">
                   Restaurant
                  </Link>
                </li>
                <li>
                  <Link to="banquet">
                   Banquet
                  </Link>
                </li>
                <li>
                  <Link to="partyLawns" className="inner-link">
                   Party Lawns
                  </Link>
                </li>
                {/* <li>
                  <Link to="foodCourt" className="inner-link">
                    Food Court
                  </Link>
                </li> */}
                <li>
                  <Link to="rooms" className="inner-link">
                    Room
                  </Link>
                </li>
                <li>
                  <Link to="contact" className="inner-link">
                    Contact
                  </Link>
                </li>
                {/* <li className="dropdown">
                <Link to="/" className="inner-link ">
                    Events  <i className="fa fa-angle-down"></i>
                  </Link>
              
                  <div className="dropdown-content">
                    <div className="menu-item">
                     
                      <Link
                        to="/"
                        aria-current="page"
                      >
                        Event Spaces
                      </Link>
                    </div>
                    <div className="menu-item ">
                      <Link to="/">
                        Weddings
                      </Link>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
            {/* <div className="col-lg-4 col-md-2 pull-right header-right text-right sm-display-none">
                       <span className="text-uppercase white-text text-small md-display-none">Book your Tables?&nbsp;&nbsp;</span>
                       <a className="btn-small-white btn btn-very-small no-margin inner-link" to="#contact">Book Now
                   </div> */}
          </div>
        </div>
      </nav>
      {/* end navigation */}
    </>
  );
}

export default Header;
