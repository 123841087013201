import React from 'react'
import { Link } from "react-router-dom";
const HomeBookRoom = () => {
  return (
    <section id="room" className="cover-background  bg-room margin-top-four">
     
    <div className="container room-content">
    
      <div className="row">
      
        <div className="col-md-12 col-sm-12 text-center">
          <span className="title-small text-uppercase display-block letter-spacing-1 margin-one no-margin-lr  xs-text-large white-text ">
            Easy Reservations
          </span>
          <h2 className="title-extra-large text-uppercase   white-text display-block xs-title-large">
            Book your Room Now!
          </h2>
          <Link
            to="/contact"
            className="highlight-button-dark btn btn-medium button margin-six no-margin-lr inner-link"
          >
            Reserve Now
          </Link>
        </div>
      </div>
    </div>
  </section>
  )
}

export default HomeBookRoom