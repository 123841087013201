import React from "react";
import ChildComponent from "./ChildComponent";
import data from "./data";

const RestaurantPageArticles = () => {
  return (
    <>
      {data.length &&
        data.map((obj, indx) => {
          return (
            <div key={indx}>
              <ChildComponent
                heading={obj.heading}
                paragraphOne={obj.paragraphOne}
                paragraphTwo={obj.paragraphTwo}
                link={obj.link}
                imageSrc={obj.imageSrc}
                rowReverse={obj.rowReverse}
                id={obj.key}
              />
            </div>
          );
        })}
    </>
  );
};

export default RestaurantPageArticles;
