import React from 'react'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
function Layout(props) {
  const { children} = props;

    return (
        <>
        <Header/>
        {children}
        <Footer/>
        </>
    )
}

export default Layout
