import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import chef from '../uploads/restaurant/chefs.webp'
import foodCourt1 from '../uploads/restaurant/foodCourt1.jpg'
import foodCourt2 from '../uploads/restaurant/foodCourt2.jpg'
import foodCourt3 from '../uploads/restaurant/foodCourt3.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import RestaurantPageArticles from "../Component/RestaurantPageArticles";
const Restaurant = () => {
  return (
    <>
    
      <section
        id="home"
        className="fix-background bg-res-image dp-bottom res-fix-background"
        style={{minHeight: "722px" }}
      >
    
        <div
          className="container full-screen position-relative  res-full-screen party-lawns-scroll"
          style={{ minHeight: "655px !important" }}
        >
          <div className="slider-typography text-center">
            <div className="slider-text-middle-main">
             
            </div>
          </div>
        </div>
      </section>

      <section id="restaurant" className="margin-top-seven">
        <div className="container sm-text-center">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <p className="title-large  text-uppercase letter-spacing-2 brown-text display-block  margin-one no-margin-top no-margin-lr">
                Dash of flavors
              </p>
              <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
            </div>
            <div className="text-content text-left margin-both-5 ">
              <p className="text-medium">
                Guardians of places that have existed long before us, our unique
                vision is inspired by nature’s magnitude, mystery and enchanting
                beauty. Founded on shared passions and masters of innovation, we
                are a thriving community working hand in hand with the
                environment to craft beautiful, beyond bespoke experiences where
                discovery is a way of life. With our expert hosts as your
                personal guide, we invite you to explore and delight in the
                possibility of each moment as time melts away and lose yourself
                in experiences that will stay with you for a lifetime.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row margin-ten no-margin-lr no-margin-bottom">
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-margin-eleven sm-no-margin-lr sm-no-margin-top xs-no-border">
                <CountUp start={0} end={3} delay={0} redraw={true}>
                  {({ countUpRef, start  }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        className="timer counter-number   brown-text"
                        ref={countUpRef}
                      />
                   </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                  Dining
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
                <CountUp start={0} end={20} delay={0}>
                  {({ countUpRef ,start}) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        className="timer counter-number   brown-text"
                        ref={countUpRef}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                Chefs
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center border-right xs-no-border xs-margin-eleven xs-no-margin-lr xs-no-margin-top">
                <CountUp start={0} end={166} delay={0}>
                  {({ countUpRef,start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        className="timer counter-number   brown-text"
                        ref={countUpRef}
                      />
                     </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                Food Recipes
                </span>
              </div>
              {/* end counter item */}
              {/* counter item */}
              <div className="col-md-3 col-sm-6 counter-style1 text-center">
                <CountUp start={0} end={3000} delay={0}>
                  {({ countUpRef,start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span
                        className="timer counter-number   brown-text"
                        ref={countUpRef}
                      />
                   </VisibilitySensor>
                  )}
                </CountUp>
                <span className="text-small  letter-spacing-2 text-uppercase margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
               People Served
                </span>
              </div>
              {/* end counter item */}
            </div>
          </div>
          <div className="row">
        
          </div>
          <div className="row no-margin-lr">
            <div className="col-md-8 col-sm-12 margin-top-seven text-center">
               <LazyLoadImage
                   src={chef}
                width={730}
                height={697}
                alt="img4"
                  />
            </div>
            <div className="col-md-4 col-sm-12 margin-nineteen no-margin-lr sm-no-margin-bottom">
              <span className="text-large text-uppercase display-block letter-spacing-1 margin-four no-margin-lr">
                Speciality of the House
              </span>
              <span className="title-extra-large text-uppercase  light-gray-text display-block xs-title-medium">
                Discover our tasty meals
              </span>
              <div className="separator-line-thick bg-deep-yellow no-margin-lr sm-margin-lr-auto sm-margin-seven"></div>
              <p className="text-medium margin-fifteen no-margin-lr sm-width-100 sm-margin-seven xs-margin-seven xs-no-margin-lr">
                The Royal Retreat offers enticing dining and entertainment
                concepts for guests and locals alike to enjoy. Our unique
                options such as grab-and-go breakfast fare and sweets,
                thoughtful cocktails along with the exclusive lounge…
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <RestaurantPageArticles />
      </section>
      <section className="outdoor-margin">
      <div className="col-md-12 col-sm-12 text-center">
              <p className="title-large  text-uppercase  brown-text display-block  margin-one no-margin-top no-margin-lr">
              Outdoor Spaces
              </p>
              <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
            </div>
        <div className="container position-relative">
          <div className="row blog-post-style2">
            <article className="col-md-12 col-sm-12 col-xs-12 no-margin-top  outdoor-container" >
            <div className="col-md-6 col-sm-6 col-xs-12 no-padding bg-white outdoor-img">
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding post-thumbnail overflow-hidden">
                  <Link to="/">
                    <img
                      src={foodCourt1}                     
                      alt="blog post-4"
                    />
                  </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding outdoor-details">
                  <div className="post-details top18P">
                    <span className="text-extra-large text-uppercase display-block  margin-twelve no-margin-lr sm-text-medium xs-text-medium">
                    <div className="post-details height-100">
                  <p className="title-medium  text-uppercase margin-bottom-8 brown-text display-block   no-margin-top no-margin-lr">
                    Food Court
                  </p>
                  <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
                  <span className="text-medium">
                Outdoor plaza or common area within a facility that is contiguous with the counters of multiple food options.
                  </span>
                </div>
                    </span>
                    {/* <div className="separator-line bg-deep-yellow margin-seventeen no-margin-bottom margin-lr-auto"></div> */}
                  </div>
                  <div className="counter-style1 outdoor-counter text-center margin-top-nine sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
                  <CountUp end={135} redraw={true} suffix="*">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="timer counter-number brown-text"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="title-small alt-font-family  letter-spacing-2  margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                    Capacity of people
                  </span>
                </div>
                </div>
              </div>
            </article>
            <article className="col-md-12 col-sm-12 col-xs-12 no-margin-top outdoor-container" >
              <div className="col-md-6 col-sm-6 col-xs-12 no-padding bg-white outdoor-img">
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding outdoor-details-middle">
                <div className="post-details top18P">
                    <span className="text-extra-large text-uppercase display-block  margin-twelve no-margin-lr sm-text-medium xs-text-medium">
                    <div className="post-details height-100">
                  <p className="title-medium  text-uppercase margin-bottom-8 brown-text display-block   no-margin-top no-margin-lr">
                    Open Restaurant
                  </p>
                  <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
                  <span className="text-medium">
                  All-Day Dining level that seats 90 covers. Aglass covered roof means best of both worlds, open to sky and indoor dining!
                  </span>
                </div>
                    </span>
                  </div>
                  <div className="counter-style1 outdoor-counter text-center margin-top-nine sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
                  <CountUp end={120} redraw={true} suffix="*">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="timer counter-number brown-text"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="title-small alt-font-family  letter-spacing-2  margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                    Capacity of people
                  </span>
                </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding post-thumbnail overflow-hidden outdoor-float-right">
                  <Link to="/">
                    <img
                      src={foodCourt2}
                     
                      alt="blog post-4"
                    />
                  </Link>
                </div>
              </div>
            </article>
            <article className="col-md-12 col-sm-12 col-xs-12 no-margin-top outdoor-container" >
              <div className="col-md-6 col-sm-6 col-xs-12 no-padding bg-white outdoor-img">
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding post-thumbnail overflow-hidden">
                  <Link to="/">
                    <img
                      src={foodCourt3}
                   
                      alt="blog post-4"
                    />
                  </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 no-padding outdoor-details">
                 <div className="post-details top18P">
                    <span className="text-extra-large text-uppercase display-block  margin-twelve no-margin-lr sm-text-medium xs-text-medium">
                    <div className="post-details height-100">
                  <p className="title-medium  text-uppercase margin-bottom-8 brown-text display-block   no-margin-top no-margin-lr">
                    Kids Zone
                  </p>
                  <div className="margin-four no-margin-top">
                <hr className="hr" />
              </div>
                  <span className="text-medium">
                  At Kidzone your child will be enjoy and fun with exciting environment  </span>
                </div>
                    </span>
                    {/* <div className="separator-line bg-deep-yellow margin-seventeen no-margin-bottom margin-lr-auto"></div> */}
                  </div>
                  <div className="counter-style1 outdoor-counter text-center margin-top-nine sm-no-border sm-margin-eleven sm-no-margin-lr sm-no-margin-top">
                  <CountUp end={30} redraw={true} suffix="*">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="timer counter-number brown-text"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="title-small alt-font-family  letter-spacing-2  margin-seven no-margin-lr display-block  xs-margin-three xs-no-margin-lr">
                    Capacity of people
                  </span>
                </div>
                </div>
              </div>
            </article> 
           
          </div>
        </div>
      </section>
    </>
  );
};

export default Restaurant;
