import React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ChildComponent = (props) => {
  const {
    heading,
    paragraphOne,
    paragraphTwo,
    imageSrc,
    rowReverse,
    id,
  } = props;
  const contentClass = rowReverse ? "row-reverse" : "";
  // const margin = rowReverse ? 'margin-right-10' : 'margin-left-10';
  return (
    <>
      <section
        id={id}
        className="no-padding fix-background md-fix-background-left min-height-image res-image-text-height"
        style={{ minHeight: "722px" }}
      >
        <div className="container full-screen position-relative">
          <div className="slider-typography text-center">
            <div className="text-image-main">
              <div className={`text-middle ${contentClass}`}>
                <div className="text-content text-left margin-both-5">
                  {/* <p className="title-large  text-uppercase  brown-text display-block  margin-one no-margin-top no-margin-lr">
                For Your All Events Need
              </p> */}
                  <h2 className="title-large text-uppercase text-center  brown-text display-block margin-four no-margin-top no-margin-lr">
                    {heading}{" "}
                  </h2>
                  <div className="margin-four no-margin-top">
                    <hr className="hr" />
                  </div>
                  <p className="margin-top-six">{paragraphOne}</p>
                  <p className="margin-top-six">{paragraphTwo}</p>
                </div>
                <div className="pull-right position-relative padding-right-img sm-margin-lr-auto sm-float-none xs-padding-twenty-three">
                  {/* <img src={imageSrc} className="home-img" width={750} height={500} alt="icon" /> */}
                  {/* <ImgWithFallback src={webSrc} fallback={imageSrc}  className="home-img" width={750} height={500} alt="icon"  /> */}
                  <LazyLoadImage
                    alt={"alt"}
                    effect="blur"
                    src={imageSrc}
                    className="home-img res-home-img"                  />
         
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChildComponent;
